import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import sidebarCollapse from "./../../../assets/sideBar/sidebarCollapse.png";
import sidebarExpand from "./../../../assets/sideBar/sidebarExpand.png";
import unselectedOverview from "./../../../assets/sideBar/unselectedOverview.png";
import overview from "./../../../assets/sideBar/overview.png";
import unselectedCampaigns from "./../../../assets/sideBar/unselectedCampaigns.png";
import campaigns from "./../../../assets/sideBar/campaigns.png";
import unselectedSales from "./../../../assets/sideBar/unselectedSales.png";
import sales from "./../../../assets/sideBar/sales.png";
import unselectedHelpAndPolicies from "./../../../assets/sideBar/unselectedHelpAndPolicies.png";
import helpAndPolicies from "./../../../assets/sideBar/helpAndPolicies.png";
import faymBranding from "./../../../assets/sideBar/faymBranding.png";
import collapsedFaymBranding from "./../../../assets/sideBar/collapsedFaymBranding.png";
import {
    setIsCollapsed,
    setSelectedPage,
} from "../../../redux/slice/sidebarSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { verifyPermission } from "../../../utilServices/permissionService";

const Index: React.FC = () => {
    const [isHovered, setIsHovered] = useState(false);
    const { selectedPage, isCollapsed } = useSelector(
        (state: any) => state.root.sidebar
    );
    const { associatedBusinesses, minicons } = useSelector(
        (state: any) => state.root.overview
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const associatedBrands = ["Foxtale", "Dennis Lingo", "Milton", "Myntra"];

    const itemsArr = [
        {
            unselectedImage: unselectedOverview,
            image: overview,
            title: "Overview",
            route: "overview",
        },
        {
            unselectedImage: unselectedCampaigns,
            image: campaigns,
            title: "Campaigns",
            route: "campaigns",
        },
        {
            unselectedImage: unselectedSales,
            image: sales,
            title: "Sales",
            route: "sales",
        },
        {
            unselectedImage: unselectedHelpAndPolicies,
            image: helpAndPolicies,
            title: "Help & Polices",
            route: "policies",
        },
    ];

    useEffect(() => {
        if (window.location.href.includes("/overview")) {
            dispatch(setSelectedPage(0));
        } else if (window.location.href.includes("/campaigns")) {
            dispatch(setSelectedPage(1));
        } else if (window.location.href.includes("/sales")) {
            dispatch(setSelectedPage(2));
        } else if (window.location.href.includes("/policies")) {
            dispatch(setSelectedPage(3));
        }
    }, []);

    return (
        <div
            className={`${styles.sideBar} ${isCollapsed ? styles.collapsed : ``}`}
        >
            <div
                className={
                    isCollapsed
                        ? styles.sideBarCollapsedBrandContainer
                        : styles.sideBarBrandContainer
                }
            >
                {!isCollapsed &&
                    associatedBusinesses?.length > 0 &&
                    minicons && (
                        <div
                            className={styles.sideBarBrandDisplay}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                        >
                            <img
                                className={styles.sideBarBrandImage}
                                src={
                                    minicons[
                                        associatedBusinesses[0]?.name?.toUpperCase()
                                    ]?.brandIcon
                                }
                                alt='brand'
                            />
                            <div className={styles.sideBarBrandName}>
                                {associatedBusinesses[0]?.name}
                            </div>
                            {verifyPermission("sidebar") && (
                                <div className={styles.dropdownIcon} />
                            )}
                        </div>
                    )}

                {!isCollapsed ? (
                    <img
                        className={styles.sideBarCollapseHandle}
                        src={sidebarCollapse}
                        alt='hamburger'
                        onClick={() => {
                            dispatch(setIsCollapsed(!isCollapsed));
                        }}
                    />
                ) : (
                    associatedBusinesses && (
                        <div
                            className={styles.collapsedBrandContainer}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            style={
                                verifyPermission("sidebar")
                                    ? {}
                                    : { justifyContent: "centre" }
                            }
                        >
                            <img
                                className={styles.sideBarBrandImage}
                                src={
                                    minicons[
                                        associatedBusinesses[0]?.name?.toUpperCase()
                                    ]?.brandIcon
                                }
                                alt='brand'
                            />
                            {verifyPermission("sidebar") && (
                                <div className={styles.dropdownIcon} />
                            )}
                        </div>
                    )
                )}
            </div>
            {isHovered && minicons && verifyPermission("sidebar") && (
                <div
                    className={styles.dropdownContainer}
                    style={isHovered ? { display: "block" } : {}}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    {associatedBrands.map((e, i) => (
                        <div>
                            <div className={styles.brandDropdownItem} key={i}>
                                <img
                                    className={styles.dropdownBrandIcons}
                                    src={
                                        minicons[
                                            associatedBrands[i]
                                                ?.toUpperCase()
                                                .replace(/\s+/g, "")
                                        ]?.brandIcon
                                    }
                                    alt='brand-image'
                                />
                                <div className={styles.dropdownBrandNames}>
                                    {e}
                                </div>
                            </div>
                            {i !== associatedBrands.length - 1 && (
                                <div
                                    className={styles.brandListSeperator}
                                ></div>
                            )}
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.sideBarItemsDisplay}>
                {itemsArr.map((e, i) => {
                    return (
                        i <= itemsArr.length - 2 && (
                            <div
                                className={
                                    isCollapsed
                                        ? styles.sideBarCollapsedItem
                                        : styles.sideBarItem
                                }
                                key={i}
                                onClick={() => {
                                    dispatch(setSelectedPage(i));
                                    navigate(`/${itemsArr[i].route}`);
                                }}
                            >
                                <div
                                    className={
                                        isCollapsed
                                            ? styles.sideBarCollapsedSelectedItemIndicator
                                            : styles.sideBarSelectedItemIndicator
                                    }
                                    style={{
                                        background:
                                            selectedPage === i ? "#2A2A2A" : "",
                                    }}
                                ></div>
                                <div
                                    className={
                                        isCollapsed
                                            ? styles.sideBarCollapsedItemContents
                                            : styles.sideBarItemContents
                                    }
                                    style={{
                                        background:
                                            selectedPage === i ? "#2A2A2A" : "",
                                    }}
                                >
                                    <img
                                        className={styles.sideBarItemImage}
                                        src={
                                            selectedPage === i
                                                ? e.image
                                                : e.unselectedImage
                                        }
                                        alt='item-image'
                                    />
                                    {!isCollapsed && (
                                        <div
                                            className={styles.sideBarItemText}
                                            style={{
                                                color:
                                                    selectedPage === i
                                                        ? "white"
                                                        : "",
                                            }}
                                        >
                                            {e.title}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )
                    );
                })}
            </div>
            <hr className={styles.itemSeparator} />
            <div
                className={
                    isCollapsed
                        ? styles.sideBarCollapsedItem
                        : styles.sideBarItem
                }
                onClick={() => {
                    dispatch(setSelectedPage(itemsArr.length - 1));
                    navigate(`/${itemsArr[itemsArr.length - 1].route}`);
                }}
            >
                <div
                    className={
                        isCollapsed
                            ? styles.sideBarCollapsedSelectedItemIndicator
                            : styles.sideBarSelectedItemIndicator
                    }
                    style={{
                        background:
                            selectedPage === itemsArr.length - 1
                                ? "#2A2A2A"
                                : "",
                    }}
                ></div>
                <div
                    className={
                        isCollapsed
                            ? styles.sideBarCollapsedItemContents
                            : styles.sideBarItemContents
                    }
                    style={{
                        background:
                            selectedPage === itemsArr.length - 1
                                ? "#2A2A2A"
                                : "",
                    }}
                >
                    <img
                        className={styles.sideBarItemImage}
                        src={
                            selectedPage === itemsArr.length - 1
                                ? itemsArr[itemsArr.length - 1].image
                                : itemsArr[itemsArr.length - 1].unselectedImage
                        }
                        alt='item-image'
                    />
                    {!isCollapsed && (
                        <div
                            className={styles.sideBarItemText}
                            style={{
                                color:
                                    selectedPage === itemsArr.length - 1
                                        ? "white"
                                        : "",
                            }}
                        >
                            Help & Policies
                        </div>
                    )}
                </div>
            </div>
            <div style={{ flex: "1" }}></div>
            <img
                className={
                    isCollapsed
                        ? styles.sideBarCollapsedFaymBranding
                        : styles.sideBarFaymBranding
                }
                src={isCollapsed ? collapsedFaymBranding : faymBranding}
                alt='faym branding'
                onClick={() => {
                    window.open("https://faym.co", "_blank");
                }}
            />
        </div>
    );
};

export default Index;
