import store from "../redux/store";

function select(state: { root: any }) {
    return state.root.auth;
}
let userData: {
    user: any;
};
function listener() {
    userData = select(store.getState());
}
store.subscribe(listener);

export const verifyPermission = (element: any) => {
    const encodeedPermissions = userData?.user?.permissions;
    let flag = 0;

    for (let i = 0; i < encodeedPermissions?.length; i++) {
        const permissionElement = atob(encodeedPermissions[i])?.split(":")[0];
        const permission = atob(encodeedPermissions[i])?.split(":")[1];
        console.log(permission);
        if (element.toLowerCase() === permissionElement.toLowerCase()) {
            if (permission === "write") {
                flag = 1;
                break;
            } else {
                flag = 0;
            }
        } else {
            flag = 0;
        }
    }
    if (flag === 1) {
        return true;
    } else {
        return false;
    }
};
