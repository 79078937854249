import { Fragment, useEffect, useState, useRef } from "react";
import styles from "./index.module.css";
import SideBar from "../common/SideBar";
import emptyCampaign from "./../../assets/campaigns/emptyCampaign.gif";
import addCampaign from "./../../assets/campaigns/addCampaign.png";
import { useSelector, useDispatch } from "react-redux";
import {
    clearCampaignsState,
    setIsCreateCampaignModalOpen,
    setIsUploadModalOpen,
    setSelectedCampaign,
} from "../../redux/slice/campaignsSlice";
import Modal from "../common/Modal";
import closeModal from "./../../assets/modal/closeModal.png";
import {
    createCampaign,
    getCampaigns,
    getTopSectionStats,
    uploadCreators,
} from "../../apiServices/campaignsService";
import GlobalLoader from "../common/GlobalLoader";
// import youtube from "./../../assets/overview/youtube.png";
import instagram from "./../../assets/overview/instagram.png";
// import selected from "./../../assets/modal/selected.png";
// import unSelected from "./../../assets/modal/unSelected.png";
import backArrow from "./../../assets/campaigns/backArrow.png";
import credits from "./../../assets/campaigns/credits.png";
import views from "./../../assets/campaigns/views.png";
import autoDmSent from "./../../assets/campaigns/autoDmSent.png";
import sales from "./../../assets/campaigns/sales.png";
import { dateConverter, getShortNumber } from "../../utilServices/utils";
import { dateFormats } from "../../enums";
import Tooltip from "./../common/Tooltip";
import likes from "./../../assets/overview/likes.png";
import comments from "./../../assets/overview/comments.png";
import shares from "./../../assets/campaigns/shares.png";
import websiteVisits from "./../../assets/campaigns/websiteVisits.png";
import SemiCircleChart from "./SemiDonutChart";
import CampaignTable from "../common/Tables/campaignTable";
import Logout from "../common/Logout/index";
import { verifyPermission } from "../../utilServices/permissionService";
import NameInitial from "../common/NameInitials/index";
import SortDataModal, { campaignDataKeyMapping } from "../common/sortDataModal";
import {
    campaignSortDropDownElements,
    campaignTableHeader,
} from "../../utilServices/tableUtils";
import downloadIcon from "../../assets/campaigns/downloadIcon.png";
import * as XLSX from "xlsx";
import noCreators from "./../../assets/campaigns/noCreators.gif";
import uploadCreatorsIcon from "./../../assets/campaigns/uploadCreators.png";
import UploadedCreatorsTable from "./../common/Tables/uploadedCreatorsTable";
import { useToast } from "../common/Toasts/ToastContext";
import sidebarExpand from "./../../assets/sideBar/sidebarExpand.png";
import { setIsCollapsed } from "../../redux/slice/sidebarSlice";

interface ExcelRow {
    [key: string]: string | number | boolean | null;
}

const Index = () => {
    const { showToast } = useToast();
    const dispatch = useDispatch();
    const { isCollapsed } = useSelector((state: any) => state.root.sidebar);
    const { user } = useSelector((state: any) => state.root.auth);
    const {
        isCreateCampaignModalOpen,
        campaigns,
        isCampaignsLoading,
        selectedCampaign,
        topSectionStats,
        websitevisit,
        topProducts,
        totalCliks,
        engagements,
        allCreators,
        overallCampaignData,
        campaignSortKey,
        isUploadModalOpen,
    } = useSelector((state: any) => state.root.campaigns);
    const { associatedBusinesses } = useSelector(
        (state: any) => state.root.overview
    );
    const date = new Date();
    date.setDate(date.getDate() - 29);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const [startDate, setStartDate] = useState(`${year}-${month}-${day}`);
    const [validations, setValidations] = useState({
        input1: false,
        input2: false,
        input3: true,
        input4: false,
        input5: user.role === "RELATIONSHIP_MANAGER" ? false : true,
        input6: user.role === "RELATIONSHIP_MANAGER" ? false : true,
        input7: user.role === "RELATIONSHIP_MANAGER" ? false : true,
    });
    const handleValidation = (inputName: string, isValid: boolean) => {
        setValidations((prevState) => ({
            ...prevState,
            [inputName]: isValid,
        }));
    };
    const isFormValid = Object.values(validations).every((isValid) => isValid);
    // const [isInstagramChecked, setIsInstagramChecked] = useState(false);
    // const [isYoutubeChecked, setIsYoutubeChecked] = useState(false);
    const [campaign, setCampaign] = useState<any>({
        startDate: startDate,
        deliverables: {},
    });
    const [endDate, setEndDate] = useState("");
    const [selectedFilter, setSelectedFilter] = useState(0);
    const [filteredCampaigns, setFilteredCampaigns] = useState(campaigns);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [isSortOpen, setIsSortOpen] = useState(false);

    const filters = [
        `All (${campaigns?.length ? campaigns?.length : 0})`,
        `Live (${
            campaigns?.length
                ? campaigns?.filter(
                      (e: { status: string }) => e?.status === "LIVE"
                  ).length
                : 0
        })`,
        `Completed (${
            campaigns?.length
                ? campaigns?.filter(
                      (e: { status: string }) => e?.status === "COMPLETED"
                  ).length
                : 0
        })`,
        `Offline (${
            campaigns?.length
                ? campaigns?.filter(
                      (e: { status: string }) => e?.status === "OFFLINE"
                  ).length
                : 0
        })`,
    ];
    const columnArr = [
        "Campaign Name",
        "Platform",
        "Influencers",
        "Created by",
        "Start Date",
        "End Date",
        "Status",
    ];

    const engagementsArr = [
        {
            title: "Likes",
            value: engagements ? engagements?.likes : 0,
            convertedValue:
                getShortNumber(engagements?.likes) === "-"
                    ? 0
                    : getShortNumber(engagements?.likes),
            icon: likes,
            color: "#E697FF",
        },
        {
            title: "Comments",
            value: engagements ? engagements?.comments : 0,
            convertedValue:
                getShortNumber(engagements?.comments) === "-"
                    ? 0
                    : getShortNumber(engagements?.comments),
            icon: comments,
            color: "#63ABFD",
        },
        {
            title: "Shares",
            value: engagements ? engagements?.shares : 0,
            convertedValue:
                getShortNumber(engagements?.shares) === "-"
                    ? 0
                    : getShortNumber(engagements?.shares),
            icon: shares,
            color: "#FF9F75",
        },
    ];
    const handleStartDateChange = (e: any) => {
        setStartDate(e.target.value);
        if (endDate && e.target.value > endDate) {
            setEndDate("");
        }
        setCampaign({
            ...campaign,
            startDate: new Date(e.target.value).toISOString(),
        });
    };

    const handleEndDateChange = (e: any) => {
        setEndDate(e.target.value);
        if (e.target.value !== "") {
            setCampaign({
                ...campaign,
                endDate: new Date(e.target.value).toISOString(),
            });
        }
        // if (startDate! > e.target.value) {
        //     showToast(
        //         "Campaign End Date can not be before the Campaign Start Data",
        //         "error"
        //     );
        // }
        handleValidation("input4", e.target.value !== "");
    };

    const submitHandler = () => {
        if (startDate > endDate) {
            showToast(
                "Campaign End Date Should always be after the Campaign Start Date",
                "error"
            );
            return;
        }
        createCampaign(campaign);
        handleCreateCampaignModalClose();
    };

    useEffect(() => {
        if (
            sessionStorage.getItem("isReloaded") === "true" ||
            campaigns === null
        ) {
            dispatch(clearCampaignsState());
            getCampaigns().then((campaignsData) =>
                setFilteredCampaigns(campaignsData)
            );
            setCampaign({ ...campaign, startDate: startDate });
            sessionStorage.removeItem("isReloaded");
        }
        const handleBeforeUnload = () =>
            sessionStorage.setItem("isReloaded", "true");

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        if (selectedCampaign === null || selectedCampaign === undefined) return;
        getTopSectionStats(filteredCampaigns[selectedCampaign]?._id);
    }, [selectedCampaign]);

    const consolidatedData = [
        {
            title: "Credits",
            icon: credits,
            value: getShortNumber(
                (topSectionStats?.autodm?.allowedAutoDms -
                    topSectionStats?.autodm?.totalDmsSent) *
                    topSectionStats?.autodm?.pricePerDm
            ),
        },
        {
            title: "Views",
            icon: views,
            value: topSectionStats?.views
                ? getShortNumber(topSectionStats?.views)
                : 0,
        },
        {
            title: "Auto DM Sent",
            icon: autoDmSent,
            value: topSectionStats?.autodm?.totalDmsSent
                ? getShortNumber(topSectionStats?.autodm?.totalDmsSent)
                : 0,
        },
        {
            title: "Sales",
            icon: sales,
            value: topSectionStats?.sales
                ? getShortNumber(topSectionStats?.sales)
                : 0,
        },
    ];

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsSortOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const downloadCampaignData = async () => {
        const engagement_data = [
            ["Brand", associatedBusinesses[0]?.name],
            ["Name of Campaign", filteredCampaigns[selectedCampaign]?.name],
            [
                "Timeline",
                `${filteredCampaigns[selectedCampaign]?.startDate} to ${filteredCampaigns[selectedCampaign]?.endDate}`,
            ],
            [
                "Faym connect credits utilized",
                topSectionStats?.autodm?.allowedAutoDms *
                    topSectionStats?.autodm?.pricePerDm -
                    topSectionStats?.autodm?.totalDmsSent *
                        topSectionStats?.autodm?.pricePerDm,
            ],
            ["Total Views", topSectionStats?.views],
            ["Auto Dm sent", topSectionStats?.autodm?.totalDmsSent],
            ["Total sales (GMV)", topSectionStats?.sales],
            ["Total website visits", websitevisit],
            ["Total likes", engagements?.likes],
            ["Total comments", engagements?.comments],
            ["Total shares", engagements?.shares],
            ["Engagement rate", "-"],
        ];

        const overall_data = [
            [
                "Creators profile",
                "IG content link",
                "YT content link",
                "Views",
                "Likes",
                "Comments",
                "Engagement Rate",
                "Auto DM sent",
                "Conversion rate",
                "Sales",
                "Website visits",
            ],
        ];
        overallCampaignData?.forEach((ele: any) => {
            overall_data.push([
                ele.userName,
                "", // IG content link, optional
                "", // YT content link, optional
                ele.views,
                ele.likes,
                ele.comments,
                ele.engagementRate,
                ele.dms,
                ele.saleConversionRate,
                ele.totalSales,
                ele.websiteVisits,
            ]);
        });

        const spacing_rows = Array(2).fill(["", ""]);

        const combined_data = [
            ...engagement_data,
            ...spacing_rows,
            ...overall_data,
        ];
        const ws = XLSX.utils.aoa_to_sheet(combined_data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Campaign Data");

        const fileName = `${
            filteredCampaigns[selectedCampaign]?.name
        }_${new Date().getTime()}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    const [excelData, setExcelData] = useState<ExcelRow[]>([]);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isSendDisabled, setIsSendDisabled] = useState<boolean>(false);

    const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (event: ProgressEvent<FileReader>) => {
                const binaryStr = event.target?.result;
                if (typeof binaryStr === "string") {
                    const workbook = XLSX.read(binaryStr, { type: "binary" });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData: (string | number | boolean | null)[][] =
                        XLSX.utils.sheet_to_json(sheet, {
                            header: 1,
                            raw: false,
                        });

                    if (jsonData.length > 0) {
                        const headers = jsonData[0] as string[];
                        const rows = jsonData.slice(1).map((row) =>
                            headers.reduce((acc, header, index) => {
                                if (header && row[index] !== undefined) {
                                    acc[header] = row[index];
                                } else {
                                    acc[header] = null;
                                }
                                return acc;
                            }, {} as ExcelRow)
                        );

                        setExcelData(rows);
                        dispatch(setIsUploadModalOpen(true));
                        console.log(isUploadModalOpen);
                        validateData(rows);
                    }
                }
            };
            reader.readAsBinaryString(file);
        }
        e.target.value = "";
    };

    const handleCancel = () => {
        dispatch(setIsUploadModalOpen(false));
        setExcelData([]);
        setErrorMessage(null);
        setIsSendDisabled(false);
    };

    function validateAndFormatPhoneNumber(phoneNumber: string) {
        let cleanedNumber = phoneNumber.replace(/\D/g, "");
        const isValidNumber = cleanedNumber.length >= 10;
        if (!isValidNumber) {
            throw new Error("Invalid phone number");
        }
        if (!cleanedNumber.startsWith("+91")) {
            cleanedNumber = "+91" + cleanedNumber;
        }

        return cleanedNumber;
    }

    function validateUsername(username: string) {
        const regex = /^[a-z0-9_]+$/;
        const isValidLength = username.length >= 3 && username.length <= 20;
        const isValidPattern = regex.test(username);
        if (!isValidLength || !isValidPattern) {
            throw new Error(
                "Username must be between 3 and 20 characters long and can only contain lowercase letters, numbers, and underscores."
            );
        }
        return true;
    }

    function validateInstagramURL(url: string): boolean {
        const instagramRegex =
            /^https:\/\/(www\.)?instagram\.com\/[a-zA-Z0-9_.]+\/?$/;
        return instagramRegex.test(url);
    }

    function validateYouTubeURL(url: string): boolean {
        const youtubeRegex =
            /^(https:\/\/)?(www\.)?(youtube\.com\/(c\/|channel\/|user\/|watch\?v=|shorts\/|@)[a-zA-Z0-9_-]+|youtu\.be\/[a-zA-Z0-9_-]+)$/;
        return youtubeRegex.test(url);
    }

    const validateData = (rows: ExcelRow[]) => {
        try {
            let hasError = false;
            rows.forEach((row) => {
                try {
                    const phone = String(row["phoneNumber"]);
                    const username = row["faymUserName"] as string;
                    const instagram = row["instagramLink"] as string;
                    const youtube = row["youtubeLink"] as string;

                    if (!phone || !validateAndFormatPhoneNumber(phone)) {
                        throw new Error("Invalid phone number.");
                    }
                    if (!username || !validateUsername(username)) {
                        throw new Error("Invalid username.");
                    }
                    if (!instagram && !youtube) {
                        throw new Error(
                            "At least one social media link (Instagram or YouTube) must be provided."
                        );
                    }
                    if (instagram && !validateInstagramURL(instagram)) {
                        throw new Error("Invalid Instagram URL.");
                    }
                    if (youtube && !validateYouTubeURL(youtube)) {
                        throw new Error("Invalid YouTube URL.");
                    }
                } catch (error) {
                    hasError = true;
                    setErrorMessage((error as Error).message);
                }
            });

            setIsSendDisabled(hasError);
        } catch (error) {
            console.error("Validation Error:", error);
            setIsSendDisabled(true);
        }
    };

    const handleSend = () => {
        if (isSendDisabled) return;
        const formattedData = excelData.map((row) => ({
            ...row,
            phoneNumber: String(row["phoneNumber"]),
        }));
        uploadCreators(filteredCampaigns[selectedCampaign]._id, formattedData);
    };

    const handleCreateCampaignModalClose = () => {
        setCampaign({ startDate: startDate });
        dispatch(setIsCreateCampaignModalOpen(false));
        setStartDate(`${year}-${month}-${day}`);
        setEndDate("");
        // setIsInstagramChecked(false);
        // setIsYoutubeChecked(false);
        setDescription("");
        setName("");
        setValidations({
            input1: false,
            input2: false,
            input3: true,
            input4: false,
            input5: user.role === "RELATUINSHIP_MANAGER" ? false : true,
            input6: user.role === "RELATUINSHIP_MANAGER" ? false : true,
            input7: user.role === "RELATUINSHIP_MANAGER" ? false : true,
        });
    };

    return (
        <>
            <div className={styles.screen}>
                {isCampaignsLoading && <GlobalLoader />}

                <SideBar />
                <div
                    className={styles.screenContent}
                    style={isCollapsed ? { width: "95%" } : {}}
                >
                    {selectedCampaign != null ? (
                        <>
                            <div className={styles.campaignHeader}>
                                <div className={styles.campaignHeaderWrapper}>
                                    {isCollapsed && (
                                        <img
                                            className={
                                                styles.sideBarCollapseHandleCollapsed
                                            }
                                            src={sidebarExpand}
                                            alt='hamburger'
                                            onClick={() => {
                                                dispatch(
                                                    setIsCollapsed(!isCollapsed)
                                                );
                                            }}
                                        />
                                    )}
                                    <img
                                        className={styles.backArrow}
                                        src={backArrow}
                                        alt='back'
                                        onClick={() => {
                                            dispatch(setSelectedCampaign(null));
                                        }}
                                    />
                                    <div
                                        className={styles.campaignDetailsHeader}
                                    >
                                        <div
                                            className={
                                                styles.campaignNameHeader
                                            }
                                        >
                                            {
                                                filteredCampaigns[
                                                    selectedCampaign
                                                ]?.name
                                            }
                                            <Tooltip
                                                info={
                                                    filteredCampaigns[
                                                        selectedCampaign
                                                    ]?.name
                                                }
                                            />
                                        </div>
                                        <div
                                            className={
                                                styles.campaignDatesHeader
                                            }
                                        >
                                            {dateConverter(
                                                filteredCampaigns[
                                                    selectedCampaign
                                                ]?.startDate,
                                                dateFormats.ddMmYy
                                            )}{" "}
                                            to{" "}
                                            {dateConverter(
                                                filteredCampaigns[
                                                    selectedCampaign
                                                ]?.endDate,
                                                dateFormats.ddMmYy
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.campaignRightWrapper}>
                                    {verifyPermission("download_report") && (
                                        <button
                                            className={styles.downloadBtn}
                                            onClick={downloadCampaignData}
                                        >
                                            Download Report
                                            <img
                                                src={downloadIcon}
                                                alt='download'
                                            />
                                        </button>
                                    )}
                                    <Logout />
                                </div>
                            </div>
                            <div className={styles.separator}></div>
                            <div className={styles.contentWrapper}>
                                <div
                                    className={styles.consolidatedInfoContainer}
                                >
                                    {consolidatedData?.map((e, i) => {
                                        return (
                                            <div
                                                className={
                                                    styles.consolidatedInfoItem
                                                }
                                                key={i}
                                            >
                                                <div
                                                    className={
                                                        styles.consolidatedInfoItemWrapper
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.consolidatedInfoTitle
                                                        }
                                                    >
                                                        {e.title}
                                                        {i === 0 && (
                                                            <Tooltip info='Faym Connect Credits' />
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.consolidatedInfoValue
                                                        }
                                                    >
                                                        {e.value}
                                                    </div>
                                                </div>
                                                <img
                                                    className={
                                                        styles.consolidateInfoIcon
                                                    }
                                                    src={e.icon}
                                                    alt={e.title}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                                <div
                                    className={styles.consolidatedInfoContainer}
                                >
                                    <div className={styles.creators}>
                                        <div className={styles.creatorsHeading}>
                                            CREATORS LIVE
                                        </div>
                                        {allCreators?.length > 0 ? (
                                            <>
                                                <div
                                                    className={
                                                        styles.valueAndUpload
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.numberOfCreators
                                                        }
                                                    >
                                                        {getShortNumber(
                                                            allCreators?.length
                                                        )}
                                                    </div>
                                                    <img
                                                        className={
                                                            styles.uploadCreators
                                                        }
                                                        src={uploadCreatorsIcon}
                                                        alt='upload'
                                                        onClick={() =>
                                                            document
                                                                .getElementById(
                                                                    "fileInput"
                                                                )
                                                                ?.click()
                                                        }
                                                    />
                                                    <input
                                                        type='file'
                                                        id='fileInput'
                                                        style={{
                                                            display: "none",
                                                        }}
                                                        accept='.xls,.xlsx'
                                                        onChange={
                                                            handleFileUpload
                                                        }
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.creatorsList
                                                    }
                                                >
                                                    {allCreators?.map(
                                                        (e: any, i: number) => {
                                                            return (
                                                                <div key={i}>
                                                                    <div
                                                                        className={
                                                                            styles.creatorsListItem
                                                                        }
                                                                    >
                                                                        {e.profileImgUrl !==
                                                                            "" &&
                                                                        e.profileImgUrl ? (
                                                                            <img
                                                                                className={
                                                                                    styles.creatorInfoImage
                                                                                }
                                                                                src={
                                                                                    e.profileImgUrl
                                                                                }
                                                                                alt='creator-image'
                                                                            />
                                                                        ) : (
                                                                            <NameInitial
                                                                                name={
                                                                                    e.name
                                                                                }
                                                                                size={
                                                                                    "2.375em"
                                                                                }
                                                                                fontSize={
                                                                                    "1rem"
                                                                                }
                                                                                userBackgroundIndex={
                                                                                    null
                                                                                }
                                                                            />
                                                                        )}
                                                                        <div
                                                                            className={
                                                                                styles.creatorInfo
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    styles.creatorName
                                                                                }
                                                                            >
                                                                                {
                                                                                    e.name
                                                                                }
                                                                            </div>
                                                                            <div
                                                                                className={
                                                                                    styles.creatorUsername
                                                                                }
                                                                            >
                                                                                {
                                                                                    e.userName
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {i <
                                                                        allCreators.length -
                                                                            1 && (
                                                                        <hr
                                                                            className={
                                                                                styles.creatorListSeparator
                                                                            }
                                                                        ></hr>
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <div
                                                className={
                                                    styles.noCreatorsContainer
                                                }
                                            >
                                                <img
                                                    className={
                                                        styles.noCreatorsGif
                                                    }
                                                    src={noCreators}
                                                    alt='0'
                                                />
                                                <div
                                                    className={
                                                        styles.noDataAvailable
                                                    }
                                                    style={{ color: "black" }}
                                                >
                                                    No Data Available
                                                </div>
                                                {verifyPermission(
                                                    "campaign"
                                                ) && (
                                                    <>
                                                        <div
                                                            className={
                                                                styles.uploadCreatorsSheet
                                                            }
                                                            onClick={() =>
                                                                document
                                                                    .getElementById(
                                                                        "fileInput"
                                                                    )
                                                                    ?.click()
                                                            }
                                                        >
                                                            Upload Creator's
                                                            Sheet
                                                        </div>
                                                        <input
                                                            type='file'
                                                            id='fileInput'
                                                            style={{
                                                                display: "none",
                                                            }}
                                                            accept='.xls,.xlsx'
                                                            onChange={
                                                                handleFileUpload
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.engagements}>
                                        <div
                                            className={
                                                styles.engagementsHeading
                                            }
                                        >
                                            ENGAGEMENTS
                                        </div>
                                        {engagements?.likes +
                                            engagements?.shares +
                                            engagements?.comments >
                                        0 ? (
                                            <>
                                                <div
                                                    className={
                                                        styles.engagementValue
                                                    }
                                                >
                                                    {getShortNumber(
                                                        engagements?.likes +
                                                            engagements?.shares +
                                                            engagements?.comments
                                                    ) === "-"
                                                        ? 0
                                                        : getShortNumber(
                                                              engagements?.likes +
                                                                  engagements?.shares +
                                                                  engagements?.comments
                                                          )}
                                                </div>
                                                <div
                                                    className={
                                                        styles.engagementList
                                                    }
                                                >
                                                    {engagementsArr?.map(
                                                        (e, i) => {
                                                            return (
                                                                <Fragment
                                                                    key={i}
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.egagementListItem
                                                                        }
                                                                    >
                                                                        <img
                                                                            className={
                                                                                styles.engagmentItemIcon
                                                                            }
                                                                            src={
                                                                                e.icon
                                                                            }
                                                                            alt='engagement-icon'
                                                                        />
                                                                        <div
                                                                            className={
                                                                                styles.engagementItemTitle
                                                                            }
                                                                        >
                                                                            {
                                                                                e.title
                                                                            }
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                styles.engagementItemValue
                                                                            }
                                                                        >
                                                                            {
                                                                                e.convertedValue
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {i <
                                                                        engagementsArr?.length -
                                                                            1 && (
                                                                        <hr
                                                                            className={
                                                                                styles.engagementSeparator
                                                                            }
                                                                        ></hr>
                                                                    )}
                                                                </Fragment>
                                                            );
                                                        }
                                                    )}
                                                    <div
                                                        className={
                                                            styles.pieChartContainer
                                                        }
                                                    >
                                                        <SemiCircleChart
                                                            data={
                                                                engagementsArr
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.engagementContainer
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.engagementRate
                                                            }
                                                        >
                                                            Eng. Rate
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.engagementRateValue
                                                            }
                                                        >
                                                            {(
                                                                ((engagements?.likes +
                                                                    engagements?.shares +
                                                                    engagements?.comments) *
                                                                    100) /
                                                                engagements?.views
                                                            ).toFixed(2)}
                                                            %
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <div
                                                className={
                                                    styles.noDataAvailable
                                                }
                                                style={{
                                                    margin: "auto",
                                                    color: "black",
                                                }}
                                            >
                                                No Data Available
                                            </div>
                                        )}
                                    </div>
                                    <div className={styles.wesbitesAndClicks}>
                                        <div className={styles.websites}>
                                            <div
                                                className={
                                                    styles.wesbiteVisitDescription
                                                }
                                            >
                                                WEBSITE VISITS
                                            </div>
                                            {getShortNumber(websitevisit) ===
                                            "-" ? (
                                                <div
                                                    className={
                                                        styles.noDataAvailable
                                                    }
                                                    style={{
                                                        color: "white",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    No Data Available
                                                </div>
                                            ) : (
                                                <>
                                                    <div
                                                        className={
                                                            styles.websiteVisitValue
                                                        }
                                                    >
                                                        {getShortNumber(
                                                            websitevisit
                                                        )}
                                                    </div>
                                                    <img
                                                        className={
                                                            styles.websiteVisits
                                                        }
                                                        src={websiteVisits}
                                                        alt='website-visits'
                                                    />
                                                </>
                                            )}
                                        </div>
                                        <div className={styles.topProducts}>
                                            <div
                                                className={
                                                    styles.topProductsHeader
                                                }
                                            >
                                                TOP PRODUCTS
                                            </div>
                                            {topProducts?.length > 0 ? (
                                                <>
                                                    <div
                                                        className={
                                                            styles.productsContainer
                                                        }
                                                    >
                                                        {topProducts?.map(
                                                            (
                                                                e: any,
                                                                i: number
                                                            ) => {
                                                                return (
                                                                    <img
                                                                        className={
                                                                            styles.productImage
                                                                        }
                                                                        src={
                                                                            e.productImage
                                                                        }
                                                                        alt='product-image'
                                                                        key={i}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.productClicksContainer
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                styles.topProductsHeader
                                                            }
                                                        >
                                                            Clicks:{" "}
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.productClicks
                                                            }
                                                        >
                                                            {getShortNumber(
                                                                totalCliks
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <div
                                                    className={
                                                        styles.noDataAvailable
                                                    }
                                                    style={{ margin: "auto" }}
                                                >
                                                    No Data Available
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.table}>
                                    <div className={styles.tableHeading}>
                                        Creator Performance
                                        <div
                                            className={styles.sortWrapper}
                                            ref={dropdownRef}
                                        >
                                            Sort by:{" "}
                                            <span
                                                onClick={() => {
                                                    setIsSortOpen(!isSortOpen);
                                                }}
                                            >
                                                {
                                                    Object.entries(
                                                        campaignDataKeyMapping
                                                    ).find(
                                                        ([key, value]) =>
                                                            value ===
                                                            campaignSortKey
                                                    )?.[0]
                                                }
                                            </span>
                                            {isSortOpen && (
                                                <SortDataModal
                                                    setIsSortOpen={
                                                        setIsSortOpen
                                                    }
                                                    sortDropDownElements={
                                                        campaignSortDropDownElements
                                                    }
                                                    page={"campaign"}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <CampaignTable
                                        tableHeaders={campaignTableHeader}
                                        overallData={overallCampaignData}
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.header}>
                                <div className={styles.headerContainer}>
                                    {isCollapsed && (
                                        <img
                                            className={
                                                styles.sideBarCollapseHandleCollapsed
                                            }
                                            src={sidebarExpand}
                                            alt='hamburger'
                                            onClick={() => {
                                                dispatch(
                                                    setIsCollapsed(!isCollapsed)
                                                );
                                            }}
                                        />
                                    )}
                                    <div
                                        className={styles.screenHeader}
                                        style={
                                            isCollapsed
                                                ? { marginLeft: "0.5em" }
                                                : {}
                                        }
                                    >
                                        Campaigns
                                    </div>
                                </div>
                                <div className={styles.rightWrapper}>
                                    {campaigns?.length > 0 &&
                                        verifyPermission("campaign") && (
                                            <div
                                                className={
                                                    styles.createCampaign
                                                }
                                                onClick={() => {
                                                    dispatch(
                                                        setIsCreateCampaignModalOpen(
                                                            true
                                                        )
                                                    );
                                                }}
                                            >
                                                <img
                                                    className={
                                                        styles.createCampaignIcon
                                                    }
                                                    src={addCampaign}
                                                    alt='create'
                                                />
                                                <div
                                                    className={
                                                        styles.createCampaignText
                                                    }
                                                >
                                                    Create Campaign
                                                </div>
                                            </div>
                                        )}
                                    <Logout />
                                </div>
                            </div>
                            <div className={styles.separator}></div>
                            {campaigns?.length === 0 ? (
                                <div className={styles.gifContainer}>
                                    <img
                                        className={styles.emptyCampaignGif}
                                        src={emptyCampaign}
                                        alt='rocket'
                                    />
                                    <div className={styles.gifHeader}>
                                        No Active Campaigns
                                    </div>
                                    <div className={styles.gifDescription}>
                                        Create your first campaign and track
                                        results in real time!
                                    </div>
                                    {verifyPermission("campaign_users") && (
                                        <div
                                            className={styles.createCampaign}
                                            onClick={() => {
                                                dispatch(
                                                    setIsCreateCampaignModalOpen(
                                                        true
                                                    )
                                                );
                                            }}
                                        >
                                            <img
                                                className={
                                                    styles.createCampaignIcon
                                                }
                                                src={addCampaign}
                                                alt='create'
                                            />
                                            <div
                                                className={
                                                    styles.createCampaignText
                                                }
                                            >
                                                Create Campaign
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <div className={styles.filterBox}>
                                        {filters?.map((e, i) => {
                                            return (
                                                <div
                                                    className={`${
                                                        styles.filterItem
                                                    } ${
                                                        selectedFilter === i &&
                                                        styles.filterItemSelected
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedFilter(i);
                                                        if (i > 0) {
                                                            setFilteredCampaigns(
                                                                campaigns.filter(
                                                                    (
                                                                        camp: any
                                                                    ) =>
                                                                        camp.status ===
                                                                        e
                                                                            .split(
                                                                                " "
                                                                            )[0]
                                                                            .toUpperCase()
                                                                )
                                                            );
                                                        } else {
                                                            setFilteredCampaigns(
                                                                campaigns
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {e}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className={styles.tableWrapper}>
                                        <div className={styles.tableContainer}>
                                            <table>
                                                <tr
                                                    className={
                                                        styles.campaignListColumns
                                                    }
                                                >
                                                    {columnArr?.map((e, i) => {
                                                        return (
                                                            <th
                                                                key={i}
                                                                className={
                                                                    styles.columnName
                                                                }
                                                            >
                                                                {e}
                                                            </th>
                                                        );
                                                    })}
                                                </tr>
                                                {(selectedFilter === 0
                                                    ? campaigns
                                                    : filteredCampaigns
                                                )?.map((e: any, i: number) => {
                                                    return (
                                                        <>
                                                            <tr
                                                                className={
                                                                    styles.campaignListItem
                                                                }
                                                                onClick={() => {
                                                                    dispatch(
                                                                        setSelectedCampaign(
                                                                            i
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            styles.campaignName
                                                                        }
                                                                    >
                                                                        {
                                                                            e?.name
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <img
                                                                        className={
                                                                            styles.socialMediaImage
                                                                        }
                                                                        src={
                                                                            instagram
                                                                        }
                                                                        alt='instagram'
                                                                    />
                                                                    {/* <img
                                                                    className={
                                                                        styles.socialMediaImage
                                                                    }
                                                                    src={
                                                                        youtube
                                                                    }
                                                                    alt='youtube'
                                                                /> */}
                                                                </td>
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {
                                                                        e?.creators
                                                                    }
                                                                </td>
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {
                                                                        e?.createdBy
                                                                    }
                                                                </td>
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {dateConverter(
                                                                        e?.startDate,
                                                                        dateFormats.ddMmYy
                                                                    )}
                                                                </td>
                                                                <td
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {dateConverter(
                                                                        e?.endDate,
                                                                        dateFormats.ddMmYy
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <div
                                                                        className={`${
                                                                            styles.tableData
                                                                        } ${
                                                                            e?.status ===
                                                                                "LIVE" &&
                                                                            styles.campaignStatus
                                                                        }`}
                                                                    >
                                                                        {
                                                                            e?.status
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            {i <
                                                                filteredCampaigns?.length -
                                                                    1 && (
                                                                <tr>
                                                                    {columnArr.map(
                                                                        (
                                                                            e,
                                                                            i
                                                                        ) => {
                                                                            return (
                                                                                <td
                                                                                    className={
                                                                                        styles.creatorsListSeparatorContainer
                                                                                    }
                                                                                    key={
                                                                                        i
                                                                                    }
                                                                                >
                                                                                    <hr
                                                                                        className={
                                                                                            styles.creatorsListSeparator
                                                                                        }
                                                                                    ></hr>
                                                                                </td>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tr>
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </table>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <Modal
                showModal={isCreateCampaignModalOpen}
                onClose={handleCreateCampaignModalClose}
                alignItems={"center"}
            >
                <div
                    className={styles.createCampaignModalContainer}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={styles.modalHeader}>
                        <div className={styles.modalHeading}>
                            Create new campaign
                        </div>
                        <img
                            className={styles.closeModal}
                            src={closeModal}
                            alt='close-modal'
                            onClick={() =>
                                dispatch(setIsCreateCampaignModalOpen(false))
                            }
                        />
                    </div>
                    <div className={styles.campaignDetails}>
                        <div
                            className={`${styles.inputContainer} ${styles.inputContainerName}`}
                        >
                            <div className={styles.inputLabel}>
                                Campaign Name*
                            </div>
                            <input
                                className={styles.inputBox}
                                placeholder='Name your campaign'
                                value={name}
                                onChange={(e) => {
                                    setCampaign({
                                        ...campaign,
                                        name: e.target.value,
                                    });
                                    setName(e.target.value);
                                    handleValidation(
                                        "input1",
                                        e.target.value !== ""
                                    );
                                }}
                                maxLength={40}
                            />
                            <div className={styles.characterCount}>
                                {name.length}/40
                            </div>
                        </div>
                        <div className={styles.descriptionAndPlatform}>
                            <div
                                className={`${styles.inputContainer} ${styles.inputContainerDescription}`}
                            >
                                <div className={styles.inputLabel}>
                                    Campaign Description*
                                </div>
                                <textarea
                                    className={styles.inputBox}
                                    placeholder='Define your campaign'
                                    onChange={(e) => {
                                        setDescription(e.target.value);
                                        setCampaign({
                                            ...campaign,
                                            description: e.target.value,
                                        });
                                        handleValidation(
                                            "input2",
                                            e.target.value !== ""
                                        );
                                    }}
                                    maxLength={350}
                                />
                                <div className={styles.characterCount}>
                                    {description.length}/350
                                </div>
                            </div>
                            {/* <div className={styles.platformsContainer}>
                                <div className={styles.inputLabel}>
                                    Platforms*
                                </div>
                                <div className={styles.platformsList}>
                                    <div
                                        className={
                                            styles.platformSelectContainer
                                        }
                                    >
                                        <img
                                            className={styles.platformSelect}
                                            src={
                                                isInstagramChecked
                                                    ? selected
                                                    : unSelected
                                            }
                                            onClick={() => {
                                                setCampaign({
                                                    ...campaign,
                                                    platforms: [
                                                        {
                                                            youtube:
                                                                isYoutubeChecked,
                                                            instagram:
                                                                !isInstagramChecked,
                                                        },
                                                    ],
                                                });
                                                setIsInstagramChecked(
                                                    !isInstagramChecked
                                                );
                                            }}
                                            alt='checkbox'
                                        />
                                        <img
                                            className={styles.platformIcon}
                                            src={instagram}
                                            alt='platform'
                                        />
                                    </div>
                                    <div
                                        className={
                                            styles.platformSelectContainer
                                        }
                                    >
                                        <img
                                            className={styles.platformSelect}
                                            src={
                                                isYoutubeChecked
                                                    ? selected
                                                    : unSelected
                                            }
                                            onClick={() => {
                                                setCampaign({
                                                    ...campaign,
                                                    platforms: [
                                                        {
                                                            youtube:
                                                                !isYoutubeChecked,
                                                            instagram:
                                                                isInstagramChecked,
                                                        },
                                                    ],
                                                });
                                                setIsYoutubeChecked(
                                                    !isYoutubeChecked
                                                );
                                            }}
                                            alt='checkbox'
                                        />
                                        <img
                                            className={styles.platformIcon}
                                            src={youtube}
                                            alt='platform'
                                        />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className={styles.dateContainer}>
                        <div className={styles.inputContainer}>
                            <div className={styles.inputLabel}>Start Date*</div>
                            <input
                                className={styles.inputBox}
                                type='date'
                                id='start-date'
                                value={startDate}
                                onChange={handleStartDateChange}
                                min={dateConverter(
                                    associatedBusinesses
                                        ? associatedBusinesses[0]?.createdAt
                                        : "",
                                    dateFormats.yyyyMmDd
                                )}
                            />
                        </div>
                        <div className={styles.inputContainer}>
                            <div className={styles.inputLabel}>End Date*</div>
                            <input
                                className={styles.inputBox}
                                type='date'
                                id='end-date'
                                value={endDate}
                                onChange={handleEndDateChange}
                                min={startDate}
                            />
                        </div>
                    </div>
                    {/* <div className={styles.deliverablesContainer}>
                        <div className={styles.inputLabel}>Youtube</div>
                        <div className={styles.deliverables}>
                            <div
                                className={`${styles.deliverableInputBox} ${styles.youtube}`}
                            >
                                <div className={styles.deliverableInputLable}>
                                    Shorts:
                                </div>
                                <input
                                    className={styles.deliverableInput}
                                    onChange={(e) => {
                                        setCampaign({
                                            ...campaign,
                                            deliverables: {
                                                ...campaign.deliverables,
                                                short: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                            <div
                                className={`${styles.deliverableInputBox} ${styles.youtube}`}
                            >
                                <div className={styles.deliverableInputLable}>
                                    Videos:
                                </div>
                                <input
                                    className={styles.deliverableInput}
                                    onChange={(e) => {
                                        setCampaign({
                                            ...campaign,
                                            deliverables: {
                                                ...campaign.deliverables,
                                                video: e.target.value,
                                            },
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    </div> */}
                    {user.role === "RELATIONSHIP_MANAGER" && (
                        <div className={styles.deliverablesContainer}>
                            <div className={styles.inputLabel}>
                                Deliverables
                            </div>
                            <div className={styles.deliverables}>
                                <div
                                    className={`${styles.deliverableInputBox} ${styles.instagram}`}
                                >
                                    <div
                                        className={styles.deliverableInputLable}
                                    >
                                        Story:
                                    </div>
                                    <input
                                        className={styles.deliverableInput}
                                        onChange={(e) => {
                                            setCampaign({
                                                ...campaign,
                                                deliverables: {
                                                    ...campaign.deliverables,
                                                    story: e.target.value,
                                                },
                                            });
                                            handleValidation(
                                                "input5",
                                                e.target.value !== ""
                                            );
                                        }}
                                        type='number'
                                    />
                                </div>
                                <div
                                    className={`${styles.deliverableInputBox} ${styles.instagram}`}
                                >
                                    <div
                                        className={styles.deliverableInputLable}
                                    >
                                        Reels:
                                    </div>
                                    <input
                                        className={styles.deliverableInput}
                                        onChange={(e) => {
                                            setCampaign({
                                                ...campaign,
                                                deliverables: {
                                                    ...campaign.deliverables,
                                                    reel: e.target.value,
                                                },
                                            });
                                            handleValidation(
                                                "input6",
                                                e.target.value !== ""
                                            );
                                        }}
                                        type='number'
                                    />
                                </div>
                                <div
                                    className={`${styles.deliverableInputBox} ${styles.instagram}`}
                                >
                                    <div
                                        className={styles.deliverableInputLable}
                                    >
                                        Posts:
                                    </div>
                                    <input
                                        className={styles.deliverableInput}
                                        onChange={(e) => {
                                            setCampaign({
                                                ...campaign,
                                                deliverables: {
                                                    ...campaign.deliverables,
                                                    post: e.target.value,
                                                },
                                            });
                                            handleValidation(
                                                "input7",
                                                e.target.value !== ""
                                            );
                                        }}
                                        type='number'
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div
                        className={styles.modalSubmitButton}
                        onClick={isFormValid ? submitHandler : () => {}}
                        style={
                            !isFormValid
                                ? { background: "#E3E2E7", color: "#77767B" }
                                : {}
                        }
                    >
                        Create Campaign
                    </div>
                </div>
            </Modal>
            <Modal
                showModal={isUploadModalOpen}
                onClose={handleCancel}
                alignItems={"center"}
            >
                <div
                    className={styles.modalContent}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div
                        className={styles.modalHeader}
                        style={{
                            width: "100%",
                        }}
                    >
                        <div className={styles.modalHeading}>
                            Import Creators
                        </div>
                        <img
                            className={styles.closeModal}
                            src={closeModal}
                            alt='close'
                            onClick={handleCancel}
                        />
                    </div>
                    <div className={styles.uploadedCreatorsTable}>
                        {excelData.length > 0 && (
                            <UploadedCreatorsTable
                                tableHeaders={[
                                    "Creator",
                                    "Phone Number",
                                    "Instagram",
                                    "Youtube",
                                ]}
                                overallData={excelData}
                            />
                        )}
                    </div>
                    {errorMessage && (
                        <div className={styles.errorMessage}>
                            {errorMessage}
                        </div>
                    )}
                    <div className={styles.buttonContainer}>
                        <div
                            onClick={handleCancel}
                            className={styles.cancelButton}
                        >
                            Cancel
                        </div>
                        <div
                            onClick={isSendDisabled ? () => {} : handleSend}
                            className={styles.sendButton}
                            style={
                                isSendDisabled
                                    ? {
                                          background: "#E3E2E7",
                                          color: " #77767B",
                                      }
                                    : {}
                            }
                        >
                            Confirm
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default Index;
