import React, { Fragment, useEffect, useState, useRef } from "react";
import styles from "./index.module.css";
import tableItemDropdownClose from "./../../../assets/overview/tableItemDropdownClose.png";
import tableItemDropdownOpen from "./../../../assets/overview/tableItemDropdownOpen.png";
import dropDownIcon from "./../../../assets/overview/dropDownIcon.png";
import OverviewSubTable from "../SubTables/overviewSubTable";
import NameInitials from "../NameInitials/index";
import { getCreatorWiseSalesData } from "../../../apiServices/overviewService";
import { useSelector, useDispatch } from "react-redux";
import { getHeight } from "../../../utilServices/tableUtils";
import {
    setPageNumber,
    setOverviewPageSize,
} from "../../../redux/slice/overviewSlice";
import DropDown from "../SubTables/DropDown/index";
import { getShortNumber } from "../../../utilServices/utils";
import Shimmer from "../Shimmer";
import { Flex } from "antd";

interface props {
    isExpandable: boolean;
    userWiseSales: any;
    overallData: any;
    tableHeaders: any;
    subTableHeader: any;
}

const Index: React.FC<props> = ({
    isExpandable,
    userWiseSales,
    overallData,
    tableHeaders,
    subTableHeader,
}) => {
    const [selectedRow, setSelectedRow] = useState<number | null>(null);
    const { pageSize, pageNumber, totalPageSize } = useSelector(
        (state: any) => state.root.overview
    );
    const [localSelectedUser, setLocalSelectedUser] = useState(null);
    const dispatch = useDispatch();
    const [dropDown, setDropDown] = useState(false);
    const dropDownRef = useRef<HTMLDivElement>(null);

    const getCreatorWiseSales = (faymUserId: any) => {
        getCreatorWiseSalesData(faymUserId, pageSize, pageNumber);
    };

    useEffect(() => {
        if (localSelectedUser === null) return;
        getCreatorWiseSalesData(localSelectedUser, pageSize, pageNumber);
    }, [pageSize, pageNumber, overallData]);

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropDownRef.current &&
            !dropDownRef.current.contains(event.target as Node)
        ) {
            setDropDown(false);
        }
    };

    useEffect(() => {
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <table>
                <thead>
                    <tr className={styles.creatorPerformanceListColumns}>
                        {tableHeaders?.map((e: any, i: number) => {
                            return (
                                <th key={i} className={styles.columnName}>
                                    {e}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {overallData?.length ? (
                        overallData?.map((e: any, i: number) => {
                            return (
                                <Fragment key={i}>
                                    <tr
                                        className={`${
                                            styles.creatorPerformanceListItem
                                        } ${selectedRow === i && styles.selectedRow}`}
                                        onClick={() => {
                                            selectedRow === null &&
                                                getCreatorWiseSales(e?._id);
                                            isExpandable && selectedRow === i
                                                ? setSelectedRow(null)
                                                : setSelectedRow(i);
                                            dispatch(setOverviewPageSize(50));
                                            dispatch(setPageNumber(1));
                                        }}
                                    >
                                        <td>
                                            <div
                                                className={
                                                    styles.creatorDetailsContent
                                                }
                                            >
                                                {e.profileImgUrl !== "" &&
                                                e.profileImgUrl ? (
                                                    <img
                                                        className={
                                                            styles.creatorDetailImage
                                                        }
                                                        src={e.profileImgUrl}
                                                        alt='creator-image'
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            marginRight:
                                                                "0.625em",
                                                            marginLeft:
                                                                "1.25em",
                                                        }}
                                                    >
                                                        <NameInitials
                                                            name={
                                                                e.name
                                                                    ? e.name
                                                                    : "faym user"
                                                            }
                                                            size={"38px"}
                                                            fontSize={"1rem"}
                                                            userBackgroundIndex={
                                                                null
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        styles.creatorDetails
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.creatorDetailName
                                                        }
                                                    >
                                                        {e.name}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.creatorStoreLink
                                                        }
                                                    >
                                                        <a
                                                            href={e.faymWall}
                                                            target='_blank'
                                                            rel='noreferrer'
                                                        >
                                                            @{e.userName}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {e?.socials?.map(
                                                (item: any, index: number) => {
                                                    return item.platformName !==
                                                        "YOUTUBE" ? (
                                                        <div
                                                            className={
                                                                styles.followersContainer
                                                            }
                                                            key={index}
                                                        >
                                                            <img
                                                                className={
                                                                    styles.socialMediaImage
                                                                }
                                                                src={
                                                                    item.platformIcon
                                                                }
                                                                alt={
                                                                    item.platformName
                                                                }
                                                            />
                                                            <div
                                                                className={
                                                                    styles.tableData
                                                                }
                                                            >
                                                                {getShortNumber(
                                                                    item.stats
                                                                        .followers
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    );
                                                }
                                            )}
                                        </td>
                                        <td>
                                            {getShortNumber(e.contentPieces)}
                                        </td>
                                        <td>{getShortNumber(e.views)}</td>
                                        <td>{getShortNumber(e.likes)}</td>
                                        <td>{getShortNumber(e.comments)}</td>
                                        <td>{getShortNumber(e.dms)}</td>
                                        <td>
                                            {getShortNumber(e.totalSalesAmount)}
                                        </td>
                                        <td
                                            className={styles.dropdownContainer}
                                        >
                                            <div
                                                className={
                                                    styles.dropdownContent
                                                }
                                            >
                                                <div
                                                    className={styles.tableData}
                                                >
                                                    {getShortNumber(
                                                        e.websiteVisits
                                                    )}
                                                </div>
                                                {isExpandable && (
                                                    <img
                                                        className={
                                                            styles.itemDropdown
                                                        }
                                                        src={
                                                            selectedRow === i
                                                                ? tableItemDropdownOpen
                                                                : tableItemDropdownClose
                                                        }
                                                        alt='item-dropdown'
                                                    />
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                    {selectedRow === i && (
                                        <tr
                                            className={styles.subtableRow}
                                            style={
                                                selectedRow === i
                                                    ? {
                                                          height: `${
                                                              230 +
                                                              (userWiseSales?.length
                                                                  ? userWiseSales?.length -
                                                                    1
                                                                  : 1) *
                                                                  getHeight(
                                                                      userWiseSales?.length
                                                                  )
                                                          }px `,
                                                      }
                                                    : {}
                                            }
                                        >
                                            <td className={styles.subtable}>
                                                <OverviewSubTable
                                                    userWiseSales={
                                                        userWiseSales
                                                    }
                                                    subTableHeader={
                                                        subTableHeader
                                                    }
                                                />
                                            </td>
                                            {selectedRow === i && (
                                                <tr>
                                                    <td>
                                                        <div
                                                            className={
                                                                styles.footer
                                                            }
                                                        >
                                                            <div>
                                                                Page{" "}
                                                                {pageNumber} of{" "}
                                                                {totalPageSize}
                                                            </div>
                                                            <div
                                                                className={
                                                                    styles.btnParentWrapper
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        styles.btnWrapper
                                                                    }
                                                                >
                                                                    Page:{" "}
                                                                    {pageNumber}
                                                                    <div
                                                                        className={
                                                                            styles.btnGroup
                                                                        }
                                                                    >
                                                                        <button
                                                                            disabled={
                                                                                pageNumber <=
                                                                                1
                                                                            }
                                                                            onClick={() => {
                                                                                dispatch(
                                                                                    setPageNumber(
                                                                                        pageNumber -
                                                                                            1
                                                                                    )
                                                                                );
                                                                                setLocalSelectedUser(
                                                                                    e._id
                                                                                );
                                                                            }}
                                                                            style={
                                                                                pageNumber <=
                                                                                1
                                                                                    ? {
                                                                                          cursor: "not-allowed",
                                                                                      }
                                                                                    : {
                                                                                          cursor: "pointer",
                                                                                      }
                                                                            }
                                                                        >
                                                                            -
                                                                        </button>
                                                                        <button
                                                                            disabled={
                                                                                pageNumber >=
                                                                                totalPageSize
                                                                            }
                                                                            onClick={() => {
                                                                                dispatch(
                                                                                    setPageNumber(
                                                                                        pageNumber +
                                                                                            1
                                                                                    )
                                                                                );
                                                                                setLocalSelectedUser(
                                                                                    e._id
                                                                                );
                                                                            }}
                                                                            style={
                                                                                pageNumber >=
                                                                                totalPageSize
                                                                                    ? {
                                                                                          cursor: "not-allowed",
                                                                                      }
                                                                                    : {
                                                                                          cursor: "pointer",
                                                                                      }
                                                                            }
                                                                        >
                                                                            +
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        position:
                                                                            "relative",
                                                                    }}
                                                                    ref={
                                                                        dropDownRef
                                                                    }
                                                                >
                                                                    <button
                                                                        className={`${styles.dropDownBtn} ${
                                                                            dropDown &&
                                                                            styles.dropDown
                                                                        }`}
                                                                        onClick={() =>
                                                                            setDropDown(
                                                                                !dropDown
                                                                            )
                                                                        }
                                                                    >
                                                                        Page
                                                                        Size:{" "}
                                                                        {
                                                                            pageSize
                                                                        }
                                                                        <img
                                                                            src={
                                                                                dropDownIcon
                                                                            }
                                                                            alt='drop down icon'
                                                                        />
                                                                    </button>
                                                                    {dropDown && (
                                                                        <DropDown
                                                                            setDropDown={
                                                                                setDropDown
                                                                            }
                                                                            setLocalSelectedUser={
                                                                                setLocalSelectedUser
                                                                            }
                                                                            user={
                                                                                e._id
                                                                            }
                                                                            location={
                                                                                "overview"
                                                                            }
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tr>
                                    )}
                                </Fragment>
                            );
                        })
                    ) : overallData === null ? (
                        [0, 0, 0].map((e: any, i: number) => {
                            return (
                                <Fragment key={i}>
                                    <tr
                                        className={`${
                                            styles.creatorPerformanceListItem
                                        } ${selectedRow === i && styles.selectedRow}`}
                                    >
                                        <td>
                                            <div
                                                className={
                                                    styles.creatorDetailsContent
                                                }
                                            >
                                                <div
                                                    className={
                                                        styles.creatorDetailImage
                                                    }
                                                    style={{
                                                        borderRadius: "999px",
                                                        overflow: "hidden",
                                                    }}
                                                >
                                                    <Shimmer />
                                                </div>
                                                <div
                                                    className={
                                                        styles.creatorDetails
                                                    }
                                                    style={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            height: "1em",
                                                            width: "7em",
                                                            display: "flex",
                                                        }}
                                                    >
                                                        @
                                                        <Shimmer />
                                                    </div>
                                                    <div
                                                        style={{
                                                            height: "1.25em",
                                                            width: "3em",
                                                        }}
                                                        className={
                                                            styles.creatorStoreLink
                                                        }
                                                    >
                                                        <Shimmer />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    height: "1em",
                                                    width: "2em",
                                                }}
                                            >
                                                <Shimmer />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    height: "1em",
                                                    width: "2em",
                                                }}
                                            >
                                                <Shimmer />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    height: "1em",
                                                    width: "2em",
                                                }}
                                            >
                                                <Shimmer />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    height: "1em",
                                                    width: "2em",
                                                }}
                                            >
                                                <Shimmer />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    height: "1em",
                                                    width: "2em",
                                                }}
                                            >
                                                <Shimmer />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    height: "1em",
                                                    width: "2em",
                                                }}
                                            >
                                                <Shimmer />
                                            </div>
                                        </td>
                                        <td>
                                            <div
                                                style={{
                                                    height: "1em",
                                                    width: "2em",
                                                }}
                                            >
                                                <Shimmer />
                                            </div>
                                        </td>
                                        <td
                                            className={styles.dropdownContainer}
                                        >
                                            <div
                                                className={
                                                    styles.dropdownContent
                                                }
                                            >
                                                <div
                                                    style={{
                                                        height: "1em",
                                                        width: "2em",
                                                    }}
                                                >
                                                    <Shimmer />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
            {overallData?.length === 0 && (
                <div
                    className={styles.noDataAvailable}
                    style={{ justifyContent: "center" }}
                >
                    No Data Available
                </div>
            )}
        </>
    );
};

export default Index;
