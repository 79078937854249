import React from "react";
import styles from "./index.module.css";
import faymIcon from "./../../../assets/login/faymIcon.png";
import googleIcon from "./../../../assets/login/googleIcon.png";
import underline from "./../../../assets/login/underline.png";
import { googleSignIn } from "../../../apiServices/authenticationService";
import { useSelector } from "react-redux";
import Loader from "./../../common/Loader";

const Index = () => {
    const { loginLoading } = useSelector((state: any) => state.root.auth);
    return (
        <div className={styles.leftContainer}>
            <div className={styles.loginContainer}>
                <img className={styles.faymLogo} src={faymIcon} alt='faym' />
                <div className={styles.faymMoto}>Welcome To Faym Vue!
                <img className={styles.underline} src={underline} alt='underline' />
                </div>
                <div className={styles.faymDescription}>
                    Enabling Tech Driven campaigns, Powered by Creativity.
                </div>
                <div
                    className={styles.loginButton}
                    onClick={
                        !loginLoading
                            ? async () => {
                                  await googleSignIn();
                              }
                            : () => {}
                    }
                >
                    {!loginLoading ? (
                        <>
                            <img
                                className={styles.googleLogo}
                                src={googleIcon}
                                alt='google'
                            />
                            <div className={styles.googleSignInText}>
                                Sign In with Google
                            </div>
                        </>
                    ) : (
                        <Loader length={""} />
                    )}
                </div>
            </div>   
        </div>
    );
};

export default Index;
