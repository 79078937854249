import React from "react";
import styles from "./index.module.css";
import topoGraph from "./../../../assets/login/topoGraph.png";
import topLeftCorner from "./../../../assets/login/topLeftCorner.png";
import centreAnalytics from "./../../../assets/login/centreAnalytics.png";
import creators from "./../../../assets/login/creators.png";
import reachAndViews from "./../../../assets/login/reachAndViews.png";
import websiteVisits from "./../../../assets/login/websiteVisits.png";
import paperAeroplane from "./../../../assets/login/paperAeroplane.gif";
const index = () => {
    return (
      <div className={styles.rightContainer}>
        <img
          className={styles.topoGraph}
          src={topoGraph}
          alt='topograph'
        />
        <img
          className={styles.topLeftCorner}
          src={topLeftCorner}
          alt='top left corner'
        />
        <img
          className={styles.bottomRightCorner}
          src={topLeftCorner}
          alt='bottom right corner'
        />
        <div className={styles.rightContainerWrapper}>
          <img
            className={styles.creators}
            src={creators}
                alt='centre analytics'
          />
          <img
            className={styles.paperAeroplane}
            src={paperAeroplane}
            alt='paper aeroplane'
            />
          <img
            className={styles.centreAnalytics}
            src={centreAnalytics}
            alt='centre analytics'
          />
          <img
            className={styles.reachAndViews}
            src={reachAndViews}
            alt='centre analytics'
          />
          <img
            className={styles.websiteVisits}
            src={websiteVisits}
            alt='centre analytics'
          />
        </div>
      </div>
    );
};

export default index;
