import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    associatedBusinesses: null,
    isOverviewLoading: false,
    stats: null,
    creators: null,
    engagements: null,
    overallOverviewData: null,
    overallSortKey: null,
    creatorWiseSalesData: null,
    totalPageSize: null,
    pageNumber: 1,
    pageSize: 50,
    minicons: null
};

const overviewSlice = createSlice({
    name: "overview",
    initialState,
    reducers: {
        setAssociatedBusinesses: (
            state: { associatedBusinesses: any },
            action: { payload: any }
        ) => {
            state.associatedBusinesses = action.payload;
        },
        setIsOverviewLoading: (
            state: { isOverviewLoading: any },
            action: { payload: any }
        ) => {
            state.isOverviewLoading = action.payload;
        },
        setStats: (state, action) => {
            state.stats = action.payload;
        },
        setCreators: (state, action) => {
            state.creators = action.payload;
        },
        setEngagements: (state, action) => {
            state.engagements = action.payload;
        },
        setOverallOverviewData: (state, action) => {
            state.overallOverviewData = action.payload;
        },
        setOverallSortKey: (state, action) => {
            state.overallSortKey = action.payload;
        },
        setCreatorWiseSalesData: (state, action) => {
            state.creatorWiseSalesData = action.payload;
        },
        setTotalPageSize: (state, action) => {
            state.totalPageSize = action.payload;
        },
        setPageNumber: (state, action) => {
            state.pageNumber = action.payload;
        },
        setOverviewPageSize: (state, action) => {
            state.pageSize = action.payload;
        },
        setMinicons: (state, action) => {
            state.minicons = action.payload
        },
        clearOverviewState: () => {
            return initialState;
        },
    },
});

export const {
    setAssociatedBusinesses,
    setIsOverviewLoading,
    setStats,
    setCreators,
    setEngagements,
    setOverallOverviewData,
    setOverallSortKey,
    setCreatorWiseSalesData,
    setTotalPageSize,
    setPageNumber,
    setOverviewPageSize,
    setMinicons,
    clearOverviewState,
} = overviewSlice.actions;
export const selectOverview = (state: { overview: any }) => state.overview;
export default overviewSlice.reducer;
