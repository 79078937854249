import React, { Fragment } from "react";
import styles from "./index.module.css";
import NameInitials from "../NameInitials/index";
import { getShortNumber } from "../../../utilServices/utils";
import Shimmer from "../Shimmer";

interface props {
    tableHeaders: any;
    overallData: any;
}

const Index: React.FC<props> = ({ tableHeaders, overallData }) => {
    return (
        <>
            <table>
                <thead>
                    <tr className={styles.creatorPerformanceListColumns}>
                        {tableHeaders?.map((e: any, i: number) => {
                            return (
                                <th key={i} className={styles.columnName}>
                                    {e}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {overallData?.length ? (
                        overallData?.map((e: any, i: number) => {
                            return (
                                <Fragment key={i}>
                                    <tr
                                        className={
                                            styles.creatorPerformanceListItem
                                        }
                                    >
                                        <td>
                                            <div
                                                className={
                                                    styles.creatorDetailsContent
                                                }
                                            >
                                                {e.profileImgUrl !== "" &&
                                                e.profileImgUrl ? (
                                                    <img
                                                        className={
                                                            styles.creatorDetailImage
                                                        }
                                                        src={e.profileImgUrl}
                                                        alt='creator-image'
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            marginRight:
                                                                "0.625em",
                                                            marginLeft:
                                                                "1.25em",
                                                        }}
                                                    >
                                                        <NameInitials
                                                            name={
                                                                e.name
                                                                    ? e.name
                                                                    : "faym user"
                                                            }
                                                            size={"38px"}
                                                            fontSize={"1rem"}
                                                            userBackgroundIndex={
                                                                null
                                                            }
                                                        />
                                                    </div>
                                                )}
                                                <div
                                                    className={
                                                        styles.creatorDetails
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.creatorDetailName
                                                        }
                                                    >
                                                        {e.name}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.creatorStoreLink
                                                        }
                                                    >
                                                        <a
                                                            href={e.faymWall}
                                                            target='_blank'
                                                            rel='noreferrer'
                                                        >
                                                            @{e.userName}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {e?.socials?.map(
                                                (item: any, index: number) => {
                                                    return (
                                                        item.platformName ===
                                                            "INSTAGRAM" && (
                                                            <div
                                                                className={
                                                                    styles.followersContainer
                                                                }
                                                                key={index}
                                                            >
                                                                <img
                                                                    className={
                                                                        styles.socialMediaImage
                                                                    }
                                                                    src={
                                                                        item.platformIcon
                                                                    }
                                                                    alt={
                                                                        item.platformName
                                                                    }
                                                                />
                                                                <div
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {getShortNumber(
                                                                        item
                                                                            .stats
                                                                            .followers
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    );
                                                }
                                            )}
                                        </td>
                                        <td>{getShortNumber(e.views)}</td>
                                        <td>{getShortNumber(e.likes)}</td>
                                        <td>{getShortNumber(e.comments)}</td>
                                        <td>
                                            {e.engagementRate
                                                ? e.engagementRate
                                                : "-"}
                                        </td>
                                        <td>{getShortNumber(e.dms)}</td>
                                        <td>
                                            {e.saleConversionRate
                                                ? e.saleConversionRate
                                                : "-"}
                                        </td>
                                        <td>
                                            {getShortNumber(e.totalSalesAmount)}
                                        </td>
                                        <td>
                                            {getShortNumber(e.websiteVisits)}
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })
                    ) : overallData === null ? (
                        [0, 0, 0].map((e: any, i: number) => {
                            return (
                                <Fragment key={i}>
                                    <tr
                                        className={
                                            styles.creatorPerformanceListItem
                                        }
                                    >
                                        <td>
                                            <div
                                                className={
                                                    styles.creatorDetailsContent
                                                }
                                            >
                                                {/* {e.profileImgUrl !== "" &&
                                                  e.profileImgUrl ? (
                                                      <img
                                                          className={
                                                              styles.creatorDetailImage
                                                          }
                                                          src={e.profileImgUrl}
                                                          alt='creator-image'
                                                      />
                                                  ) : (
                                                      <div
                                                          style={{
                                                              marginRight:
                                                                  "0.625em",
                                                              marginLeft:
                                                                  "1.25em",
                                                          }}
                                                      >
                                                          <NameInitials
                                                              name={
                                                                  e.name
                                                                      ? e.name
                                                                      : "faym user"
                                                              }
                                                              size={"38px"}
                                                              fontSize={"1rem"}
                                                              userBackgroundIndex={
                                                                  null
                                                              }
                                                          />
                                                      </div>
                                                  )} */}
                                                <Shimmer />
                                                <div
                                                    className={
                                                        styles.creatorDetails
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            styles.creatorDetailName
                                                        }
                                                    >
                                                        {e.name}
                                                    </div>
                                                    <div
                                                        className={
                                                            styles.creatorStoreLink
                                                        }
                                                    >
                                                        <a
                                                            href={e.faymWall}
                                                            target='_blank'
                                                            rel='noreferrer'
                                                        >
                                                            @{e.userName}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {e?.socials?.map(
                                                (item: any, index: number) => {
                                                    return (
                                                        item.platformName ===
                                                            "INSTAGRAM" && (
                                                            <div
                                                                className={
                                                                    styles.followersContainer
                                                                }
                                                                key={index}
                                                            >
                                                                <img
                                                                    className={
                                                                        styles.socialMediaImage
                                                                    }
                                                                    src={
                                                                        item.platformIcon
                                                                    }
                                                                    alt={
                                                                        item.platformName
                                                                    }
                                                                />
                                                                <div
                                                                    className={
                                                                        styles.tableData
                                                                    }
                                                                >
                                                                    {getShortNumber(
                                                                        item
                                                                            .stats
                                                                            .followers
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )
                                                    );
                                                }
                                            )}
                                        </td>
                                        <td>{getShortNumber(e.views)}</td>
                                        <td>{getShortNumber(e.likes)}</td>
                                        <td>{getShortNumber(e.comments)}</td>
                                        <td>
                                            {e.engagementRate
                                                ? e.engagementRate
                                                : "-"}
                                        </td>
                                        <td>{getShortNumber(e.dms)}</td>
                                        <td>
                                            {e.saleConversionRate
                                                ? e.saleConversionRate
                                                : "-"}
                                        </td>
                                        <td>
                                            {getShortNumber(e.totalSalesAmount)}
                                        </td>
                                        <td>
                                            {getShortNumber(e.websiteVisits)}
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
            {overallData?.length === 0 && (
                <div
                    className={styles.noDataAvailable}
                    style={{ justifyContent: "center" }}
                >
                    No Data Available
                </div>
            )}
        </>
    );
};

export default Index;
