import styles from "./index.module.css";
import SideBar from "../common/SideBar";
import { useSelector, useDispatch } from "react-redux";
import GlobalLoader from "./../common/GlobalLoader";
import NotFound from "./salesNotFound";
import { Fragment, useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import SalesTable from "../common/Tables/salesTable";
import {
    getCumulativeSalesData,
    getCampaignCreators,
    getOverallSalesData,
} from "./../../apiServices/salesService";
import {
    Creator,
    TopCreators,
    TopProducts,
    MonthlySales,
} from "./../../models/salesModel";
import dropDown from "../../assets/sales/down-outline.png";
import NameInitials from "./../common/NameInitials/index";
import {
    salesTableHeaders,
    monthObject,
    salesSubTableHeader,
    salesSortDropDownElements,
} from "../../utilServices/tableUtils";
import {
    setStartDate,
    setEndDate,
    clearSalesState,
} from "../../redux/slice/salesSlice";
import { getCampaigns } from "../../apiServices/campaignsService";
import Logout from "../common/Logout/index";
import SortDataModal, { salesDataKeyMapping } from "../common/sortDataModal";
import { getShortNumber } from "../../utilServices/utils";
import { getCreatorsData } from "../../apiServices/overviewService";
import sidebarExpand from "./../../assets/sideBar/sidebarExpand.png";
import { setIsCollapsed } from "../../redux/slice/sidebarSlice";

const Index = () => {
    const {
        isSalesLoading,
        creatorsData,
        topCreators,
        topProducts,
        monthlySales,
        totalSaleAmount,
        overallSalesData,
        userWiseSales,
        startDate,
        endDate,
        sortSelectedKey,
        salesTableColumns,
    } = useSelector((state: any) => state.root.sales);
    const { campaigns } = useSelector((state: any) => state.root.campaigns);
    const { isCollapsed } = useSelector((state: any) => state.root.sidebar);
    const [data, setData] = useState(true);
    const [creator, setCreator] = useState("");
    const [campaign, setCampaign] = useState("");
    const dispatch = useDispatch();
    const [isSortOpen, setIsSortOpen] = useState(false);

    const graphref = useRef<HTMLCanvasElement | null>(null);
    const chartInstanceRef = useRef<Chart | null>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (graphref.current) {
            chartInstanceRef.current = new Chart(graphref.current, {
                type: "bar",
                options: {
                    plugins: {
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        x: {
                            grid: { display: false },
                        },
                        y: {
                            display: false,
                        },
                    },
                },
                data: {
                    labels: monthlySales?.map(
                        (e: MonthlySales) => monthObject[e._id]
                    ),
                    datasets: [
                        {
                            label: "",
                            data: monthlySales?.map(
                                (e: MonthlySales) => e.totalSales
                            ),
                            backgroundColor: "#165BAA",
                        },
                    ],
                },
            });
        }
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [monthlySales]);

    useEffect(() => {
        if (
            sessionStorage.getItem("isReloaded") === "true" ||
            monthlySales === null
        ) {
            dispatch(clearSalesState());
            sessionStorage.removeItem("isReloaded");
            getCumulativeSalesData(creator, startDate, endDate, campaign);
            getOverallSalesData(startDate, endDate);
            getCampaigns();
            getCampaignCreators("");
        }
        const handleBeforeUnload = () =>
            sessionStorage.setItem("isReloaded", "true");

        window.addEventListener("beforeunload", handleBeforeUnload);
        window.addEventListener("mousedown", handleClickOutside);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {}, []);

    const handelCampaignChange = (e: any) => {
        if (e.target.value === "all") {
            setCampaign("");
            getCumulativeSalesData(creator, startDate, endDate, "");
            getCampaignCreators("");
        } else {
            setCampaign(e.target.value);
            const selectedCampaign = campaigns?.filter(
                (item: any) => item._id === e.target.value
            );
            getCumulativeSalesData(creator, startDate, endDate, e.target.value);
            getCampaignCreators(e.target.value);
            dispatch(
                setStartDate(selectedCampaign[0]?.startDate.split("T")[0])
            );
        }
    };

    const handelCreatorChange = (e: any) => {
        if (e.target.value === "all") {
            setCreator("");
            getCumulativeSalesData("", startDate, endDate, campaign);
        } else {
            setCreator(e.target.value);
            getCumulativeSalesData(
                e.target.value,
                startDate,
                endDate,
                campaign
            );
        }
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setIsSortOpen(false);
        }
    };

    const handelDate = (e: any, type: any) => {
        if (type === "endDate") {
            dispatch(setEndDate(e.target.value));
        } else {
            dispatch(setStartDate(e.target.value));
        }
        getOverallSalesData(startDate, endDate);
        getCumulativeSalesData(creator, startDate, endDate, campaign);
    };

    return (
        <div className={styles.screen}>
            {isSalesLoading && <GlobalLoader />}
            <SideBar />
            <div
                className={styles.screenContent}
                style={isCollapsed ? { width: "95%" } : {}}
            >
                <div className={styles.header}>
                    <div className={styles.headerContainer}>
                        {isCollapsed && (
                            <img
                                className={
                                    styles.sideBarCollapseHandleCollapsed
                                }
                                src={sidebarExpand}
                                alt='hamburger'
                                onClick={() => {
                                    dispatch(setIsCollapsed(!isCollapsed));
                                }}
                            />
                        )}
                        <div
                            className={styles.screenHeader}
                            style={isCollapsed ? { marginLeft: "0.5em" } : {}}
                        >
                            Sales
                        </div>
                    </div>
                    <Logout />
                </div>
                <div className={styles.separator}></div>
                {!data ? (
                    <NotFound />
                ) : (
                    <div className={styles.contentWrapper}>
                        <div className={styles.row1}>
                            <div className={styles.inputWrapper}>
                                <label htmlFor=''>Creator Name</label>
                                <div className={styles.customSelect}>
                                    <select
                                        name='creators'
                                        onChange={handelCreatorChange}
                                    >
                                        <option value='all'>All</option>
                                        {creatorsData?.map(
                                            (e: Creator, i: number) => {
                                                return (
                                                    <option
                                                        value={e?.faymUserId}
                                                        key={i}
                                                    >
                                                        {e?.name}
                                                    </option>
                                                );
                                            }
                                        )}
                                    </select>
                                    <img src={dropDown} alt='drop down' />
                                </div>
                            </div>
                            <div className={styles.inputWrapper}>
                                <label htmlFor=''>Campaign</label>
                                <div className={styles.customSelect}>
                                    <select
                                        name='campaign'
                                        onChange={handelCampaignChange}
                                    >
                                        <option value='all'>All</option>
                                        {campaigns?.map((e: any, i: number) => {
                                            return (
                                                <option key={i} value={e._id}>
                                                    {e.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <img src={dropDown} alt='drop down' />
                                </div>
                            </div>
                            <div className={styles.inputWrapper}>
                                <label htmlFor=''>Select your start date</label>
                                <input
                                    type='date'
                                    max={
                                        endDate
                                            ? new Date(
                                                  new Date(endDate).getTime() -
                                                      1000 * 60 * 60 * 24
                                              )
                                                  .toISOString()
                                                  .split("T")[0]
                                            : new Date(
                                                  Date.now() -
                                                      1000 * 60 * 60 * 24
                                              )
                                                  .toISOString()
                                                  .split("T")[0]
                                    }
                                    value={startDate}
                                    onChange={(e) => handelDate(e, "startDate")}
                                />
                            </div>
                            <div className={styles.inputWrapper}>
                                <label htmlFor=''>Select your end date</label>
                                <input
                                    type='date'
                                    max={new Date().toISOString().split("T")[0]}
                                    min={
                                        new Date(
                                            startDate
                                                ? new Date(
                                                      new Date(
                                                          startDate
                                                      ).getTime() +
                                                          1000 * 60 * 60 * 24
                                                  )
                                                      .toISOString()
                                                      .split("T")[0]
                                                : new Date(
                                                      Date.now() -
                                                          1000 *
                                                              60 *
                                                              60 *
                                                              24 *
                                                              29
                                                  )
                                        )
                                            .toISOString()
                                            .split("T")[0]
                                    }
                                    value={endDate}
                                    onChange={(e) => handelDate(e, "endDate")}
                                />
                            </div>
                        </div>
                        <div className={styles.row2}>
                            <div className={styles.graphWrapper}>
                                <div className={styles.graphInfo}>
                                    <h6>TOTAL SALES</h6>
                                    {getShortNumber(totalSaleAmount) !==
                                        "-" && (
                                        <h3>
                                            ₹{getShortNumber(totalSaleAmount)}
                                        </h3>
                                    )}
                                </div>
                                {getShortNumber(totalSaleAmount) !== "-" ? (
                                    <div className={styles.graph}>
                                        <canvas
                                            ref={graphref}
                                            height='150px'
                                            width='600px'
                                        ></canvas>
                                    </div>
                                ) : (
                                    <div
                                        className={styles.noDataAvailable}
                                        style={{
                                            height: "100%",
                                            marginTop: "0.625em",
                                        }}
                                    >
                                        No Data Available
                                    </div>
                                )}
                            </div>
                            <div className={styles.topData}>
                                <h6>TOP 3 CREATORS</h6>
                                <div className={styles.creatorDataWrapper}>
                                    {topCreators?.length ? (
                                        topCreators?.map(
                                            (e: TopCreators, i: number) => {
                                                return (
                                                    <Fragment key={i}>
                                                        <div
                                                            className={
                                                                styles.creatorInfo
                                                            }
                                                        >
                                                            {e?.profileImgUrl !==
                                                                "" ||
                                                            e.profileImgUrl ? (
                                                                <img
                                                                    src={
                                                                        e.profileImgUrl
                                                                    }
                                                                    alt='profile'
                                                                />
                                                            ) : (
                                                                <NameInitials
                                                                    name={
                                                                        e.name
                                                                    }
                                                                    size={
                                                                        "38px"
                                                                    }
                                                                    fontSize={
                                                                        "16px"
                                                                    }
                                                                    userBackgroundIndex={
                                                                        null
                                                                    }
                                                                />
                                                            )}
                                                            <div
                                                                className={
                                                                    styles.info
                                                                }
                                                            >
                                                                <h5>
                                                                    {e.name}
                                                                </h5>
                                                                <h6>
                                                                    @
                                                                    {e.userName}
                                                                </h6>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.contentSeparator
                                                            }
                                                        ></div>
                                                    </Fragment>
                                                );
                                            }
                                        )
                                    ) : (
                                        <div
                                            className={styles.noDataAvailable}
                                            style={{ margin: "auto" }}
                                        >
                                            No Data Available
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={styles.topProducts}>
                                <h6>TOP PRODUCTS</h6>
                                <div className={styles.creatorDataWrapper}>
                                    {topProducts?.length ? (
                                        topProducts?.map(
                                            (e: TopProducts, i: number) => {
                                                return (
                                                    <Fragment key={i}>
                                                        <div
                                                            className={
                                                                styles.productInfoWrapper
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    e.productImage
                                                                }
                                                                alt='product'
                                                            />
                                                            <div
                                                                className={
                                                                    styles.productInfo
                                                                }
                                                            >
                                                                <div>
                                                                    <p>
                                                                        SOLD :
                                                                    </p>
                                                                    <h6>
                                                                        {getShortNumber(
                                                                            e.salesCount
                                                                        )}
                                                                    </h6>
                                                                </div>
                                                                <div>
                                                                    <p>
                                                                        CLICKS :
                                                                    </p>
                                                                    <h6>
                                                                        {getShortNumber(
                                                                            e.clicks
                                                                        )}
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={
                                                                styles.productSeparator
                                                            }
                                                        ></div>
                                                    </Fragment>
                                                );
                                            }
                                        )
                                    ) : (
                                        <div
                                            className={styles.noDataAvailable}
                                            style={{ margin: "auto" }}
                                        >
                                            No Data Available
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.row3}>
                            <div className={styles.tableHeader}>
                                <div className={styles.leftContainer}>
                                    <h5>Creator Performance</h5>
                                </div>
                                <div
                                    className={styles.sortWrapper}
                                    ref={dropdownRef}
                                >
                                    Sort by:{" "}
                                    <span
                                        onClick={() => {
                                            setIsSortOpen(!isSortOpen);
                                        }}
                                    >
                                        {
                                            Object.entries(
                                                salesDataKeyMapping
                                            ).find(
                                                ([key, value]) =>
                                                    value === sortSelectedKey
                                            )?.[0]
                                        }
                                    </span>
                                    {isSortOpen && (
                                        <SortDataModal
                                            setIsSortOpen={setIsSortOpen}
                                            sortDropDownElements={
                                                salesSortDropDownElements
                                            }
                                            page={"sales"}
                                        />
                                    )}
                                </div>
                            </div>
                            <SalesTable
                                isExpandable={true}
                                overallSalesData={overallSalesData}
                                userWiseSales={userWiseSales}
                                tableHeaders={salesTableColumns?.map(
                                    (item: any) => item[0]
                                )}
                                subTableHeader={salesSubTableHeader}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Index;
