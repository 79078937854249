import {
    setAssociatedBusinesses,
    setIsOverviewLoading,
    setStats,
    setCreators,
    setEngagements,
    setOverallOverviewData,
    setOverallSortKey,
    setCreatorWiseSalesData,
    setTotalPageSize,
    setMinicons,
} from "../redux/slice/overviewSlice";
import store from "../redux/store";
import { getAPIData } from "../utilServices/utils";

function select(state: { root: any }) {
    return state.root.auth;
}
function userSelect(state: { root: any }) {
    return state.root.overview;
}
let userData: {
    user: any;
};

let business: { associatedBusinesses: any };

function listener() {
    userData = select(store.getState());
    business = userSelect(store.getState());
}
store.subscribe(listener);

const getEndDate = () => {
    return new Date();
};

export const getAssociatedBusiness = async () => {
    try {
        store.dispatch(setIsOverviewLoading(true));
        const assocBusinessResponse = await getAPIData({
            url: `business/associated/list?memberId=${userData.user._id}&memberRole=${userData.user.role}`,
        });
        if (assocBusinessResponse.data.message === "Request Successful") {
            store.dispatch(
                setAssociatedBusinesses(assocBusinessResponse.data.data)
            );
            getMinicons();
            getStats();
            getCreatorsData();
            getEngagements();
            getOverallData();
        }
    } catch (error: any) {
        console.log(error.message);
        store.dispatch(setIsOverviewLoading(false));
    } finally {
        store.dispatch(setIsOverviewLoading(false));
    }
};

export const getMinicons = async () => {
    try {
        store.dispatch(setIsOverviewLoading(true));
        const minicons = await getAPIData({url: "api/utils/v2/brands/minicons"});
        if (minicons.data.message === "Request Successful"){
            store.dispatch(setMinicons(minicons.data.data))
        }
    } catch (error) {
        console.log(error);
        store.dispatch(setIsOverviewLoading(false));
    } finally {
        store.dispatch(setIsOverviewLoading(false));
    }
}

export const getCreatorsData = async () => {
    const endDate = getEndDate();
    try {
        store.dispatch(setIsOverviewLoading(true));
        const response = await getAPIData({
            url: `business/campaignCreators?businessId=${
                userData?.user?.businessId
            }&startDate=${
                business?.associatedBusinesses[0]?.createdAt
            }&endDate=${new Date(endDate).toISOString()}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setCreators(response?.data?.data));
        }
    } catch (error) {
        console.log(error);
        store.dispatch(setIsOverviewLoading(false));
    } finally {
        store.dispatch(setIsOverviewLoading(false));
    }
};

export const getStats = async () => {
    const endDate = getEndDate();
    try {
        store.dispatch(setIsOverviewLoading(true));
        const response = await getAPIData({
            url: `business/overview/productStats?startDate=${
                business?.associatedBusinesses[0]?.createdAt
            }&endDate=${new Date(endDate).toISOString()}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setStats(response?.data?.data));
        }
    } catch (error) {
        console.log(error);
        store.dispatch(setIsOverviewLoading(false));
    } finally {
        store.dispatch(setIsOverviewLoading(false));
    }
};

export const getEngagements = async () => {
    try {
        store.dispatch(setIsOverviewLoading(true));
        const endDate = getEndDate();
        const response = await getAPIData({
            url: `business/overview/engagement?startDate=${
                business?.associatedBusinesses[0]?.createdAt
            }&endDate=${endDate}`,
        });
        if (response.data.code === 200) {
            store.dispatch(setEngagements(response?.data?.data));
        }
    } catch (error: any) {
        console.error(error);
        store.dispatch(setIsOverviewLoading(false));
    } finally {
        store.dispatch(setIsOverviewLoading(false));
    }
};

export const getOverallData = async () => {
    try {
        store.dispatch(setIsOverviewLoading(true));
        const endDate = getEndDate();
        const response = await getAPIData({
            url: `business/overview/overall?startDate=${
                business?.associatedBusinesses[0]?.createdAt
            }&endDate=${new Date(endDate).toISOString()}`,
        });
        if (response.data.code === 200) {
            let data = response?.data?.data?.result;
            data = data?.map((ele: any) => {
                ele?.socials?.forEach((item: any) => {
                    if (ele.cummulativeFollowers) {
                        ele.cummulativeFollowers += item.stats.follower;
                    } else {
                        ele.cummulativeFollowers = item.stats.follower;
                    }
                });
                return ele;
            });
            store.dispatch(setOverallOverviewData(data));
            store.dispatch(setOverallSortKey(response?.data?.data?.sortKey));
        }
    } catch (error: any) {
        console.error(error?.message);
        store.dispatch(setIsOverviewLoading(false));
    } finally {
        store.dispatch(setIsOverviewLoading(false));
    }
};

export const getCreatorWiseSalesData = async (
    faymUserId: string,
    pageSize: number,
    pageNumber: number
) => {
    try {
        const endDate = getEndDate();
        const response = await getAPIData({
            url: `business/overview/creatorwise?businessId=${
                userData?.user?.businessId
            }&startDate=${
                business?.associatedBusinesses[0]?.createdAt
            }&endDate=${new Date(
                endDate
            ).toISOString()}&faymUserId=${faymUserId}&pageSize=${pageSize}&pageNum=${pageNumber}`,
        });
        if (response?.data?.code === 200) {
            store.dispatch(
                setCreatorWiseSalesData(response?.data?.data?.result)
            );
            store.dispatch(setTotalPageSize(response?.data?.data?.totalPages));
        }
    } catch (error) {
        console.error(error);
    } finally {
    }
};
